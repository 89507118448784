$ ->

  loc = window.location
  ws = new ReconnectingWebSocket "ws" + (if loc.protocol == "https:" then "s" else "") + "://" + loc.host + "/ws"

  vmRoll = $.views.viewModels {
    id: 'id'
    getters: [
      'id'
      'name'
      'successes'
      'failures'
    ]
  }

  protectEditable = (name)->
    getter=(value)->
      if !arguments.length
        return this['_'+name]
      if this._editable
        return
      $.observable(this).setProperty(name, value)
    getter.set=(value)->
      this['_'+name]=value
    return getter

  protectOverwrite = (name)->
    getter=(value)->
      if !arguments.length
        return this['_'+name]
    getter.set=(value)->
      this['_'+name]=value
    return getter

  vmChar = $.views.viewModels {
    id: 'id'
    getters: [
      'id'
      'name'
      'alpha'
      'beta'
      'gamma'
      'delta'
      'mod'
      {getter: 'editable', defaultVal: false}
    ]
    extend: {
      name: protectEditable('name')
      alpha: protectEditable('alpha')
      beta: protectEditable('beta')
      gamma: protectEditable('gamma')
      delta: protectEditable('delta')
      mod: protectOverwrite('mod')
      editable: protectEditable('editable')
    }
  }


  rolls = vmRoll.map []
  chars = vmChar.map []

  helpers = {
    charroll: (e)->
      skill = e.target.name
      skillval = if skill=='mod' then 0 else parseInt this['_'+skill]
      modval = parseInt this._mod
      if isNaN skillval
        skillval = 0
      if isNaN modval
        modval = 0
      both = skillval + modval
      if both > 0
        $.observable(this).setProperty 'mod',''
        ws.send(JSON.stringify {cmd:'roll', name: this._name, amount: both})
      return false
    useroll: (e)->
      ws.send(JSON.stringify {cmd:'use', id: this._id, which: e.target.name})
    delchar: ->
      ws.send(JSON.stringify {cmd:'del char',id:this._id})
      return false
    prevdef: (e)->
      e.preventDefault()
      return false
  }

  window.addChar= ->
    ws.send(JSON.stringify {cmd:'make char'})

  $.observe chars,'**', (ev,evArgs)->
    if evArgs.change == 'set' and ev.target._editable==true
      ws.send(JSON.stringify {cmd:'set char',char: ev.target.unmap()})


  $.templates("#tpl_roll").link '#rolls', rolls, helpers
  $.templates("#tpl_char").link '#chars', chars, helpers


  console.log chars

  ws.onmessage = (event)->
    msg = JSON.parse event.data
    if msg.gamedata and msg.gamedata.chars
      chars.merge msg.gamedata.chars
    if msg.gamedata and msg.gamedata.roll_results
      rolls.merge msg.gamedata.roll_results

  setInterval((-> ws.send(JSON.stringify {cmd:'update gamedata'})), 1000)
